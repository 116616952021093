@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  
  body {
    @apply bg-gray-900 text-gray-100 antialiased;
  }
}

@layer components {
  .section-title {
    @apply text-3xl sm:text-4xl font-bold text-gray-100 mb-4;
  }
  
  .section-divider {
    @apply w-20 h-1 bg-purple-600 mx-auto;
  }

  .glass-effect {
    @apply bg-gray-800/30 backdrop-blur-lg border border-gray-700/50;
  }

  .gradient-text {
    @apply text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-500;
  }
}

/* Custom gradient animations */
.animate-gradient {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} 